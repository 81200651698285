import * as React from 'react';
import * as _ from 'lodash';
import {
  MedicareQuoteOutput,
  MutationSaveMedicareQuoteArgs,
  SaveMedicareQuoteOutput
} from '@coverright/data-access/types/medicare';
import {
  getCId,
  GetDefaultPlansYear,
  GetNextPlansYear,
  GetPlansYear,
  getQuoteId,
  listenQuoteIdChange,
  setQuoteId
} from '@coverright/utils';
import { ProfileContext, withProfileContext } from './ProfileContext';
import { FetchResult, MutationHookOptions, QueryResult } from '@apollo/client';
import { MutationPatchClientPersonalInfoArgs } from '@coverright/data-access/types/enrollment';
import { useMedicareQuote, useSaveMedicareQuote } from '@coverright/data-access/medicare';
import { usePatchClientPersonalInfo } from '@coverright/data-access/enrollment';
import { AdminContext } from './AdminContext';


type IQuoteContext = MedicareQuoteOutput & {
  requestData?: QueryResult,
  saveLoading: boolean,
  saveClientInfoLoading: boolean,
  save: (data: {variables:  MutationSaveMedicareQuoteArgs}) => Promise<FetchResult<{saveMedicareQuote: SaveMedicareQuoteOutput}>>,
  saveClientInfo: (data: MutationHookOptions<{ patchClientPersonalInfo: boolean }, MutationPatchClientPersonalInfoArgs>) => Promise<FetchResult<{patchClientPersonalInfo: boolean}>>,
};

const defaultValue: IQuoteContext = {
  save: () => new Promise<FetchResult<{saveMedicareQuote: SaveMedicareQuoteOutput}>>(() => null),
  saveClientInfo: () => new Promise<FetchResult<{patchClientPersonalInfo: boolean}>>(() => null),
  saveLoading: false,
  saveClientInfoLoading: false,
  id: '',
  clientId: '',
  pin: '',
  favorites: [],
  preferredDoctors: [],
  preferredDrugs: [],
  preferredPharmacies: [],
  zip: '',
}


export const QuoteContext = React.createContext<IQuoteContext>(defaultValue);

const Provider = (props: React.PropsWithChildren<QuoteProviderProps>) => {

  const profile = React.useContext(ProfileContext);
  const adminContext = React.useContext(AdminContext);

  const [saveClientInfo, {loading: saveClientInfoLoading}] = usePatchClientPersonalInfo({
    onCompleted: () => {
      const quoteId = getQuoteId();
      if (quoteId) {
        getQuote({ variables: { id: quoteId } })
      }
    }
  })


  const [saveMedicareQuote,] = useSaveMedicareQuote();
  const [saveLoading, setSaveLoading] = React.useState(false);

  const save = React.useCallback(async (data: {variables:  MutationSaveMedicareQuoteArgs}): Promise<FetchResult<{saveMedicareQuote: SaveMedicareQuoteOutput}>> => {
    setSaveLoading(true);
    const saveResult = await saveMedicareQuote(data);
    setQuoteId(saveResult.data?.saveMedicareQuote.maQuoteID);
    if (profile?.profileId && !profile.quoteId) {
      profile.save({variables: {
          data: {
            quoteId: saveResult.data?.saveMedicareQuote.maQuoteID,
            cId: getCId(),
            profileId: profile.profileId,
          }
        }})
    }
    await getQuote({variables: {id: saveResult.data?.saveMedicareQuote.maQuoteID}})
    setSaveLoading(false);
    return saveResult;
  }, [saveMedicareQuote, profile, setSaveLoading])

  React.useEffect(() => {
    if (adminContext?.clientView?.quoteId) {
      setQuoteId(adminContext?.clientView?.quoteId)
    }
  }, [adminContext?.clientView?.quoteId]);

  const quoteId = React.useMemo(() => {
    return adminContext?.clientView?.quoteId || props.quoteId;
  }, [props.quoteId, adminContext?.clientView?.quoteId]);

  const [quote, setQuote] = React.useState<MedicareQuoteOutput>();

  const [getQuote, medicareQuoteData] = useMedicareQuote({
    onCompleted: data => {
      if (!_.isEqual(quote, data?.medicareQuote)) {
        setQuote(data?.medicareQuote);
      }
      if (data?.medicareQuote.planYear && ![GetPlansYear(), GetNextPlansYear()].includes(data?.medicareQuote.planYear)) {
        save({
          variables: {
            data: {
              id: data?.medicareQuote.id,
              planYear: GetDefaultPlansYear()
            }
          }
        })
      }
    },
  });

  React.useEffect(() => {
    listenQuoteIdChange(callback)
  }, []);

  React.useEffect(() => {
    callback(quoteId);
  }, [quoteId]);

  const callback = (quoteId?: string) => {
    if (quoteId) {
      getQuote({variables: {id: quoteId}});
    }
  };

  const result = React.useMemo(() => {
      return {...quote, save, saveLoading, saveClientInfo, requestData: medicareQuoteData, saveClientInfoLoading}
  }, [save, quote, saveLoading, saveClientInfo, medicareQuoteData, saveClientInfoLoading]);

  return <QuoteContext.Provider value={result as any}>
    {props.children}
  </QuoteContext.Provider>

}

export const QuoteProvider = withProfileContext(Provider);

type QuoteProviderProps = {
  quoteId?: string,
  private?: boolean,
}

export const withQuoteContext = (WrappedComponent: any) => (props: any) => {
  return (
    <QuoteProvider quoteId={getQuoteId()}>
      <WrappedComponent {...{...props}} />
    </QuoteProvider>
  )
}
