import { PlanYear } from '@coverright/data-access/types/medicare';

export function GetPlansYear(): PlanYear {
  return PlanYear[`Year${new Date().getFullYear()}` as PlanYear];
}

export function GetNextPlansYear(): PlanYear {
  return PlanYear[`Year${new Date().getFullYear() + 1}` as PlanYear];
}

export function GetDefaultPlansYear() {
  return PlanYear.Year2025;
}
