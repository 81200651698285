import React from 'react';
import * as Scroll from 'react-scroll';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import {
  GetDefaultPlansYear,
  getMedigapCompanyLogo,
  getNumbersFromString,
  GetPlansYear,
  tooltips
} from '@coverright/utils';
import { PlanFilterContext } from '../PlanFilterContext';
import { createStyles, makeStyles } from '@mui/styles';
import { SxProps } from '@mui/material';
import { Benefit, SeeMore } from '@coverright/ui/marketplaces';
import { Tooltip } from '@coverright/ui/inputs';
import { colors } from '@coverright/ui/themes';
import { PlanCategory } from '../../category/mgcategory';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import { MedigapPlanOutputWithDetails } from '@coverright/data-access/medigap';
import { AdminContext, CompareOfferContext } from '@coverright/shared/contexts';
import { ComparablePlanType } from '@coverright/data-access/types/medicare';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useCompareYearToYearByClientId, useSaveCompareYearToYear } from '@coverright/data-access/medicare';
import * as _ from 'lodash';

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: 'white',
      fontWeight: 700,
    },
    wrapper: {
      [theme.breakpoints.up('sm')]: {
        minHeight: 260,
      },
    },
    bigLogo: {
      height: 40,
      width: 120,
      minWidth: 120,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    logo: {
      height: 27,
      width: 96,
      minWidth: 96,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'left'
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: 0,
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    middleSection: {
      borderRight: '1px solid #E8ECED',
      borderLeft: '1px solid #E8ECED',
    },
    mobileBenefitImg: {
      marginTop: 3,
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      margin: '0 6px 10px',
      [theme.breakpoints.up('sm')]: {
        margin: '0 14px 10px 16px',
      },
    },
    mobileBenefitLabel: {
      width: '50%',
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)',
    },
    toggle: {
      cursor: 'pointer',
      backgroundColor: '#029094',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottomRightRadius: 4,
      borderBottomLeftRadius: 4,
    },
    enrollSection: {
      padding: '16px 20px 11px 15px',
      margin: '16px -6px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 218,
        width: 218,
        padding: '12px 17px 12px 16px',
        margin: 0,
      },
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      position: 'relative',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    medium: {
      fontWeight: 500,
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      color: 'rgba(28, 67, 79, 1)',
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14,
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14,
    },
    summaryButton: {
      padding: 4,
      whiteSpace: 'nowrap',
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important',
      }
    },
    enrollButton: {
      '& .MuiButton-label': {
        fontSize: 14,
      },
      width: '100%',
      minWidth: '129px!important',
      padding: 9,
    },
    planEnrollButton: {
      padding: '11px 44px',
      whiteSpace: 'nowrap'
    },
    excl: {
      color: colors.custom.green.variant2
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(-180deg)',
      transition: 'all 0.3s'
    },
    iconOpen: {
      transform: 'rotate(0) !important',
    },
    pharmacyLabelClass: {
      fontWeight: 400,
      fontSize: 12,
      color: colors.text.primary
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    },
    green: {
      color: '#029094',
      opacity: 1,
    },
    red: {
      color: '#DD3D3D',
      opacity: 1,
    },
  }),
);

type PlanCardProps = {
  plans: MedigapPlanOutputWithDetails[],
  zip: string,
  countyName: string,
  imgPrefix?: string,
  open?: boolean,
  onEnrollClick?: (plan: MedigapPlanOutputWithDetails) => void
  onExpandedClick?: () => void
}

function PlanCard(props : PlanCardProps) {
  const {plans, zip, countyName, imgPrefix, onEnrollClick, open} = props;
  const classes = planStyles();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const ref = React.useRef(null);
  const {values: filterValues} = React.useContext(PlanFilterContext);
  const [expanded, setExpanded] = React.useState(typeof  open === 'undefined' ? true : open);
  const [allExpanded, setAllExpanded] = React.useState(false);

  React.useEffect(() => {
    if (document.location.hash && plans.some(p => p.key === document.location.hash.slice(1))) {
      setAllExpanded(true);
    }
  }, [plans])

  const prices = React.useMemo(() => {
      let minPrice;
      let maxPrice;
      for (let i = 0; i < plans.length; i++) {
        if (plans[i].monthlyPremium) {
          const price = parseFloat(plans[i].monthlyPremium?.replace('$', '') as string);
          if (price < (minPrice || 99999999)) {
            minPrice = price;
          }
          if (price > (maxPrice || 0)) {
            maxPrice = price;
          }
        }
      }
      if (!minPrice && !maxPrice) {
        return 'N/A'
      }
      if (minPrice === maxPrice) {
        return '$' + maxPrice?.toFixed(0);
      }
      return `$${minPrice?.toFixed(0)}-$${maxPrice?.toFixed(0)}`
  }, [plans]);

  const plan: MedigapPlanOutputWithDetails = React.useMemo(() => {
      return plans.length ? plans[0] : {} as MedigapPlanOutputWithDetails;
  }, [plans]);

  const ribbon = React.useMemo(() => {
      if (filterValues?.selectedPlanCategory !== PlanCategory.MostPopular) {
        return null;
      }
      let title = '';
      switch (plan.planName) {
        case MedigapPlanName.G: title = 'Most Popular'; break;
        case MedigapPlanName.N: title = 'Best for affordability'; break;
        case MedigapPlanName.F: title = 'Most Comprehensive'; break;
        default: title = '';
      }
      return title ? <PricesButton variant={'rounded'} sx={{width: {xs: .5, md: 224}, minWidth: {md: 224}, maxWidth: 224}}>
        <Box display={'flex'} alignItems={'center'}>
          <Typography className={'bold fs-12'} sx={{color: 'white!important', letterSpacing: {xs: -1, sm: 0}}} >{title}</Typography>
          <img className={'ml-5'} src={(imgPrefix || '') + '/assets/img/ribbon.svg'} />
        </Box>
      </PricesButton> : null;
  }, [filterValues, plan]);

  const planName = React.useMemo(() => {
    switch (plan.planName) {
      case 'HDF': return 'Plan F (High Deductible)';
      case 'HDG': return 'Plan G (High Deductible)';
      case 'MA_SUPP1': return 'Supplement 1';
      case 'MA_SUPP1A': return 'Supplement 1A';
      case 'MA_CORE': return 'Core plan';
      case 'WI_75%': return 'Medigap 75% Cost Sharing Plan';
      case 'WI_50%': return 'Medigap 50% Cost Sharing Plan';
      case 'WI_BASE': return 'Medigap Basic Plan';
      case 'WI_HDED': return 'Medigap High Deductible Plan';
      default: return 'Plan ' + plan.planName;
    }
  }, [plan.planName])

  const Desktop = <Card data-test={'mg_plan_card'} className={classes.card}>
    <div className={'flex-space-between flex-align-start mb-10 mh-20 mt-16'}>
      <Typography data-test={'plan-name'} sx={{fontSize: {xs: 24, md: 28}, lineHeight: {xs: '30px', md: '35px'}}} className={'bold no-wrap'}>{planName}</Typography>
      {[MedigapPlanName.C, MedigapPlanName.F, MedigapPlanName.Hdf].includes(plan.planName as MedigapPlanName) &&
        <Typography sx={{mr: '-10px', mt: '-5px'}} className={'fs-10 ml-25'} align={'right'}>This plan is not available to Medicare beneficiaries<br />who became eligible after Jan. 1, 2020</Typography>}
      {plan.planName === MedigapPlanName.MaSupp1 && <Typography sx={{mr: '-10px', mt: '-5px'}} className={'fs-10 ml-25'} align={'right'}>Like Plan F this is not available to people who are new to Medicare on or after January 1, 2020</Typography>}
      {ribbon}
    </div>
    <div className={'flex mh-20 mb-24'}>
      <div className={'flex-column flex-space-between pr-20'}>
        <Tooltip placement={'top'} arrow title={'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium. The premium varies depending on where you live, your age, gender, whether you use tobacco products, and what time of year you apply.'}>
          <div>
            <Typography className={'bold fs-14 mb-15 lh-14'}>Monthly premium:</Typography>
            <Typography sx={{color: colors.custom.green.variant2}} className={'bold fs-32 lh-32'}>{prices}</Typography>
          </div>
        </Tooltip>
        <div>
          <Typography className={'fs-14 lh-17'}>Does not include <br/><Tooltip title={tooltips.standardPartBPremium} arrow><span className={'dark-green pointer underline fs-14 lh-17'}>standard Part B Premium</span></Tooltip></Typography>
        </div>
      </div>
      <div className={`flex-column flex-space-between ph-24 ${classes.middleSection}`}>
        <PlanCopays plan={plan} />
      </div>
      <div className={'flex-column flex-space-between pl-24'}>
        <PlanBenefits plan={plan} />
      </div>
    </div>
    <Box sx={{backgroundColor: expanded ? 'white!important' : undefined}} className={classes.toggle} onClick={() => props.onExpandedClick ? props.onExpandedClick() : setExpanded(!expanded)}>
      <Typography sx={{color: !expanded ? 'white!important' : undefined, fontWeight: !expanded ? 700 : 500}} className={'fs-12 mv-8 mr-8 underline'}>{expanded ? 'Hide' : 'Show'} available insurance companies</Typography>
      {!expanded && <img src={(imgPrefix || '') + '/assets/img/arrow-white.svg'} className={classes.icon} />}
      {expanded && <img src={(imgPrefix || '') + '/assets/img/arrow-green-2.svg'} className={classes.icon} />}
    </Box>
    <Collapse in={expanded}>
      {plans.slice(0, 2).map((d: MedigapPlanOutputWithDetails, i) => <PlanRow key={d.key}
                                                                              imgPrefix={imgPrefix}
                                                                              hideDivider={i === plans.length - 1}
                                                                              plan={d}
                                                                              zip={zip}
                                                                              countyName={countyName}
                                                                              onEnrollClick={onEnrollClick ? () => onEnrollClick(d) : undefined} />)}
      <Collapse in={allExpanded}>
        {plans.slice(2).map((d: MedigapPlanOutputWithDetails, i) => <PlanRow key={d.key}
                                                                             imgPrefix={imgPrefix}
                                                                             hideDivider={i === plans.length - 1}
                                                                             zip={zip}
                                                                             countyName={countyName}
                                                                             plan={d}
                                                                             onEnrollClick={onEnrollClick ? () => onEnrollClick(d) : undefined} />)}
      </Collapse>
      {plans.length > 2 && <Box sx={{display: 'flex', justifyContent: 'center'}} ><SeeMore sx={{my: 1}}
                                                                                           expanded={allExpanded}
                                                                                           onToggle={value => setAllExpanded(!value)} /></Box>}
    </Collapse>
  </Card>;

  const Mobile = <Card data-test={'mg_plan_card'} className={classes.card}>
    <div className={'mh-16 mt-16 mb-12'}>
      <div className={'flex flex-space-between'}>
        <div>
          <Typography className={'bold fs-24 mb-5 lh-30'}>Plan {planName}</Typography>
          <Typography className={'bold fs-14 lh-14 mb-12 no-wrap'}>Monthly premium:</Typography>
        </div>
      {ribbon}
      </div>
      <Typography sx={{color: colors.custom.green.variant2}} className={'bold fs-32 lh-32'}>{prices}</Typography>
      {[MedigapPlanName.C, MedigapPlanName.F].includes(plan.planName as MedigapPlanName) &&
      <Typography className={'fs-10 mb-15 mt-5'} align={'right'}>This plan is not available to Medicare beneficiaries<br />who became eligible after Jan. 1, 2020</Typography>}
      <Typography className={'fs-12 mt-5'}>Does not include standard Part B premium</Typography>
      <Divider sx={{my: '12px', borderColor: '#E8ECED'}}/>
      <PlanCopays plan={plan} />
      <Divider sx={{my: '12px', borderColor: '#E8ECED'}}/>
      <PlanBenefits plan={plan} />
    </div>

    <Box sx={{backgroundColor: expanded ? 'white!important' : undefined}} className={classes.toggle} onClick={() => props.onExpandedClick ? props.onExpandedClick() : setExpanded(!expanded)}>
      <Typography sx={{color: !expanded ? 'white!important' : undefined, fontWeight: !expanded ? 700 : 500}} className={'fs-12 mv-8 mr-8 underline'}>{expanded ? 'Hide' : 'Show'} available plan options</Typography>
      {!expanded && <img src={(imgPrefix || '') + '/assets/img/arrow-white.svg'} className={classes.icon} />}
      {expanded && <img src={(imgPrefix || '') + '/assets/img/arrow-green-2.svg'} className={classes.icon} />}
    </Box>
    <Collapse in={expanded}>
      {plans.slice(0, 2).map((d: MedigapPlanOutputWithDetails, i) => <PlanRow key={d.key}
                                                                              imgPrefix={imgPrefix}
                                                                              hideDivider={i === plans.length - 1}
                                                                              plan={d}
                                                                              zip={zip}
                                                                              countyName={countyName}
                                                                              onEnrollClick={onEnrollClick ? () => onEnrollClick(d) : undefined} />)}
      <Collapse in={allExpanded}>
        {plans.slice(2).map((d: MedigapPlanOutputWithDetails, i) => <PlanRow key={d.key}
                                                                             imgPrefix={imgPrefix}
                                                                             hideDivider={i === plans.length - 1}
                                                                             plan={d}
                                                                             zip={zip}
                                                                             countyName={countyName}
                                                                             onEnrollClick={onEnrollClick ? () => onEnrollClick(d) : undefined} />)}
      </Collapse>
      {plans.length > 2 && <Box sx={{display: 'flex', justifyContent: 'center'}} ><SeeMore sx={{my: 1}}
                                                                                           expanded={allExpanded}
                                                                                           onToggle={value => setAllExpanded(!value)} /></Box>}
    </Collapse>
  </Card>

  return <div className={`mt-24 ${classes.wrapper}`} ref={ref}>
    {phone ? Mobile : Desktop}
  </div>;
}

export default React.memo(PlanCard);

export const PlanCopays = ({plan}: NeedPlanProps) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const mobile = <>
    <Typography className={'bold fs-12 mb-8 lh-15'}>Copays/coinsurance:</Typography>
    <Typography className={'fs-12 lh-15 mb-15'}><strong>$0</strong> for your cost for approved Part B services, with some $20 and $50 copays</Typography>
    <Typography className={'bold fs-12 mb-8 lh-15'}>Deductibles:</Typography>
    <Typography className={'fs-12 lh-15'}><strong>{plan.deductibles.partA}</strong> (Hospital) Part A deductible</Typography>
    <Typography className={'fs-12 lh-15 mb-15'}><strong>{plan.deductibles.partB}</strong> (Medical) Part B deductible</Typography>
  </>;

  const desktop = <>
    <div>
      <Tooltip placement={'top'} arrow title={'Coverage for medical services typically received in an outpatient facility or doctor\'s office, as well as emergency room and ambulance services.'}>
        <div>
          <Typography className={'bold fs-14 mb-8 lh-14'}>Copays/coinsurance:</Typography>
          <div className={'fs-12 lh-15 mb-10'}>{plan.partBServices}</div>
        </div>
      </Tooltip>
    </div>
    <div>
      <Typography className={'bold fs-14 mb-8 lh-14'}>Deductibles:</Typography>
      {[MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(plan.planName as MedigapPlanName) && <Tooltip placement={'top'} arrow title={'This is the amount you must pay out of pocket under Original Medicare for your hospital (Part A) costs before your plan coverage begins.  This amount covers your first 60 days regardless of how long your hospital stay is.  Medicare refers to this as the Part A (hospital) deductible.'}>
        <Typography className={'fs-12 lh-15 mb-8'}><strong>{plan.deductibles.partA}</strong> annual deductible</Typography>
      </Tooltip>}
      {![MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(plan.planName as MedigapPlanName) && <Tooltip placement={'top'} arrow title={'The amount you must pay out of pocket for your Part A (Hospital) costs before your plan coverage begins.'}>
        <Typography className={'fs-12 lh-15 mb-8'}><strong>{plan.deductibles.partA}</strong> Hospital (Part A deductible)</Typography>
      </Tooltip>}
      {plan.deductibles.partB && <Tooltip placement={'top'} arrow title={'The amount you must pay out of pocket for your Part B (Medical) costs before your plan coverage begins.'}>
        <Typography className={'fs-12 lh-15'}><strong>{plan.deductibles.partB}</strong> Medical (Part B deductible)</Typography>
      </Tooltip>}
    </div>
  </>
  return phone ? mobile : desktop
}

export const PlanBenefits = ({plan}: NeedPlanProps) => <div>
  <Typography className={'bold fs-14 lh-14'}>Plan benefits:</Typography>
  <div className={'mt-8'}>
    <Tooltip placement={'top'} arrow title={'This is the amount you must pay out of pocket under Original Medicare for your hospital (Part A) costs before your plan coverage begins.  This amount covers your first 60 days regardless of how long your hospital stay is.  Medicare refers to this as the Part A (hospital) deductible.'}>
      <div>
        <Benefit inactive={false}
                 label={<Typography sx={{mt: '2px', color: plan?.features.partADeductible ? colors.custom.green.variant2 : '#DD3D3D'}}
                                    className={'fs-12 medium ml-8 no-wrap lh-13'}>Short term hospital <span className={'fs-11 regular text-primary'}>(Part A deductible)</span></Typography>}
                 value={!!plan?.features.partADeductible}/>
      </div>
    </Tooltip>
  </div>
  <div className={'mt-8'}>
    <Tooltip placement={'top'} arrow title={'This is the amount you must pay out of pocket for an extended hospital stay (>60 days)'}>
      <div>
        <Benefit inactive={false}
                 label={<Typography sx={{mt: '2px', color: plan?.features.partACopay ? colors.custom.green.variant2 : '#DD3D3D'}}
                                    className={'fs-12 medium ml-8 no-wrap lh-13'}>Long term hospital <span className={'fs-11 regular text-primary'}>(Part A copay)</span></Typography>}
                 value={!!plan?.features.partACopay}/>
      </div>
    </Tooltip>
  </div>
  <div className={'mt-8'}>
    <Tooltip placement={'top'} arrow title={'The amount you must pay out of pocket for your medical (Part B) costs before your plan coverage begins.'}>
      <div>
        <Benefit inactive={false}
                 label={<Typography sx={{mt: '2px', color: plan?.features.partBDeductible ? colors.custom.green.variant2 : '#DD3D3D'}}
                                    className={'fs-12 medium ml-8 no-wrap lh-13'}>Medical deductible <span className={'fs-11 regular text-primary'}>(Part B deductible)</span></Typography>}
                 value={!!plan?.features.partBDeductible}/>
      </div>
    </Tooltip>
  </div>
  <div className={'mt-8'}>
    <Tooltip placement={'top'} arrow title={'This refers to the amount a doctor can charge over the Medicare-approved amount.  Providers may charge up to 15% over the standard Medicare rates.'}>
      <div>
        <Benefit inactive={false}
                 label={<Typography sx={{mt: '2px', color: plan?.features.partBExcessCharges ? colors.custom.green.variant2 : '#DD3D3D'}}
                                    className={'fs-12 medium ml-8 no-wrap lh-13'}>Medical excess charges <span className={'fs-11 regular text-primary'}>(Part B excess)</span></Typography>}
                 value={!!plan?.features.partBExcessCharges}/>
      </div>
    </Tooltip>
  </div>
  <div className={'mt-8'}>
    <Tooltip placement={'top'} arrow title={'Skilled nursing facilities are Medicare-approved facilities that provide short-term extended care services following a hospital stay, at a lower level of care than provided in a hospital.'}>
      <div>
        <Benefit inactive={false}
                 label={<Typography sx={{mt: '2px', color: plan?.features.skilledNursingFacility ? colors.custom.green.variant2 : '#DD3D3D'}}
                                    className={'fs-12 medium ml-8 no-wrap lh-13'}>Skilled nursing facility</Typography>}
                 value={!!plan?.features.skilledNursingFacility}/>
      </div>
    </Tooltip>
  </div>
  <div className={'mt-8'}>
    <Tooltip placement={'top'} arrow title={'Some Medigap plans cover foreign travel emergency care if the emergency begins during the first 60 days of your trip, and if Medicare doesn\'t otherwise cover foreign emergency care.'}>
      <div>
        <Benefit inactive={false}
                 labelClass={`thin color-primary`}
                 label={<Typography sx={{mt: '2px', color: plan?.features.foreignTravelEmergency ? colors.custom.green.variant2 : '#DD3D3D'}}
                                    className={'fs-12 medium ml-8 no-wrap lh-13'}>Foreign travel emergency</Typography>}
                 value={!!plan?.features.foreignTravelEmergency}/>
      </div>
    </Tooltip>
  </div>
</div>


export const PlanLogo = React.memo(({plan, sx}: NeedPlanProps & {sx?: SxProps}) => {
  const classes = planStyles();
  const Logo = React.useMemo(() => {
    const url = getMedigapCompanyLogo(plan.parentOrgName, plan.orgName);
    if (url) {
      return <Box sx={sx} className={classes.logo} style={{backgroundImage: `url("${url}")`}}  />;
    } else {
      const text = plan.orgName === 'Accendo Ins Co' ? 'Accendo (Aetna/CVS)' : plan.title?.split(' - Plan')[0];
      return <Typography className={'bold fs-18'}>{text}</Typography>
    }
  }, [plan.parentOrgName, plan.orgName, plan.title]);

  return Logo;
})

interface PlanRowProps {
  plan: MedigapPlanOutputWithDetails,
  hideDivider: boolean,
  imgPrefix?: string,
  onEnrollClick?: () => void,
  zip: string,
  countyName: string
}
const scroll = Scroll.scroller;
const Element = Scroll.Element;

const PlanRow = ({plan, onEnrollClick, hideDivider, imgPrefix, zip, countyName}: PlanRowProps) => {
  const classes = planStyles();
  const {values} = React.useContext(PlanFilterContext)
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const { plans: compareOfferPlans, togglePlan: toggleCompareOffer, loading: compareOfferLoading } = React.useContext(CompareOfferContext);
  const adminContext = React.useContext(AdminContext);
  const [getY2Y] = useCompareYearToYearByClientId();
  const [saveY2Y] = useSaveCompareYearToYear();
  const [highlighted, setHighlighted] = React.useState(false);

  React.useEffect(() => {
    if (document.location.hash === '#' + plan.key) {
      setTimeout(() => scroll.scrollTo(plan.key, {}), 300);
      setHighlighted(true)
    }
  }, [plan.key])

  const AdminActions = () => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const addToY2Y = () => {
      if (adminContext?.clientId) {
        getY2Y({ variables: { clientId: adminContext?.clientId } }).then(res => {
          const y2y = res.data?.compareYearToYearByClientId;
          return saveY2Y({
            variables: {
              input: {
                ...({ ..._.omit(y2y, 'isLocked'), createdAt: undefined, updatedAt: undefined } || {}),
                suggestedMgNaic: plan.naic,
                suggestedMgPlanName: plan.planName,
                suggestedMgPlanKey: plan.key,
                currentYear: GetDefaultPlansYear(),
                zip,
                county: countyName,
                clientId: adminContext?.clientId,
              }
            }
          });
        })
      }
    }

    return <>
      <IconButton sx={{position: 'absolute', right: '5px', top: '5px'}} size="small" onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
          toggleCompareOffer({bidId: plan.key, type: ComparablePlanType.Mg, county: countyName, zip, effectiveDate: values?.effectiveDate})
          handleClose()
        }}>{compareOfferPlans.map(o => o.bidId).includes(plan.key) ? 'Remove from\n' : 'Add to'} compare offer</MenuItem>
        <MenuItem onClick={() => {
          addToY2Y();
          handleClose()
        }}>Add to Y2Y</MenuItem>
      </Menu>
    </>
  }

  const Desktop = <Box sx={{border: highlighted ? '2px solid ' + colors.custom.green.variant5 : undefined, borderRadius: '4px'}} className={'flex pt-12 pb-8 ph-20 flex-align-center relative'}>
    <div className={'flex-column pr-35'}>
      <div className={'flex-space-between flex-align-center mb-8'}>
        <PlanLogo plan={plan} />
        <Typography sx={{color: colors.custom.green.variant2}} className={'fs-24 lh-32'}><strong>{plan.monthlyPremium}</strong> /mo</Typography>
      </div>
      <div className={'flex'}>
        <PlanSubtitle plan={plan} />
      </div>
      <div className={'flex'}>
        <div className={'flex-column w-130'}>
          <Tooltip placement={'top'} arrow title={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
            <div className={'flex flex-align-center'}>
              <Typography className={'fs-12 w-100'}>AM Best rating:</Typography>
              <div className={'flex flex-align-center'}>
                <Typography sx={{color: colors.custom.green.variant2}} className={'fs-12 medium'}>{plan.amBestRating || 'n.a.'}</Typography>
              </div>
            </div>
          </Tooltip>
          <Tooltip placement={'top'} arrow title={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
            <div className={'flex flex-align-center'}>
              <Typography className={'fs-12 w-100'}>S&P rating:</Typography>
              <div className={'flex flex-align-center'}>
                <Typography sx={{color: colors.custom.green.variant2}} className={'fs-12 medium'}>{plan.spRating || 'n.a.'}</Typography>
              </div>
            </div>
          </Tooltip>
        </div>
        <div className={'flex-column flex-2 pt-2'}>
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <Tooltip placement={'top'} arrow title={tooltips.standardPartBPremium}>
              <div className={'flex flex-align-center flex-justify-end'}>
                <Typography className={'fs-11 '}>plus standard Part B premium</Typography>
                <img className={'w-16 h-16 ml-5'} src={(imgPrefix || '') + '/assets/img/info.svg'}></img>
              </div>
            </Tooltip>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 1}}>
            <Tooltip placement={'top'} arrow title={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'}>
              <div className={'flex flex-align-center flex-justify-end'}>
                <Typography className={'fs-12 bold mr-10 medium'}>Household discount:</Typography>
                <Typography sx={{color: colors.custom.green.variant2}} className={'fs-12 medium'}>
                  {plan.householdDiscount ? <>{plan.householdDiscount} <span className="mh-5">|</span> ${((1 - getNumbersFromString(plan.householdDiscount)[0] / 100) * getNumbersFromString(plan.monthlyPremium as string)[0]).toFixed(2)} /mo</> : 'n.a.'}
                </Typography>
                <img className={'w-16 h-16 ml-5'} src={(imgPrefix || '') + '/assets/img/info.svg'}></img>
              </div>
            </Tooltip>
          </Box>
        </div>
      </div>
    </div>
    <div>
      {onEnrollClick && <Button className={classes.enrollButton}
               data-test={'mg_select_plan_button'}
               onClick={onEnrollClick}
               variant={'contained'}>
        Select plan
      </Button>}
    </div>
    {!!adminContext && <AdminActions />}
  </Box>;

  const Mobile = <div className={'mt-16 mb-8 mh-16'}>
    <div className={'flex flex-space-between'}>
      <PlanLogo plan={plan} />
      <PlanSubtitle plan={plan} />
    </div>

    <div className={'flex-space-between mt-16'}>
      <Typography sx={{color: colors.custom.green.variant2}} className={'fs-24 lh-32'}><strong>{plan.monthlyPremium}</strong> / mo</Typography>
      <Tooltip placement={'top'} arrow title={tooltips.standardPartBPremium}>
        <div className={'flex flex-align-center flex-justify-end w-120'}>
          <Typography className={'fs-11 lh-13'} align={'right'}>plus standard<br />Part B premium</Typography>
          <img className={'w-16 h-16 ml-10'} src={(imgPrefix || '') + '/assets/img/info.svg'}></img>
        </div>
      </Tooltip>
    </div>
    <div className={'flex-space-between mt-15'}>
      <div className={'w-120'}>
        <Tooltip placement={'top'} arrow title={'An AM Best credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
          <div className={'flex flex-align-center flex-space-between'}>
            <Typography className={'fs-12 lh-15 '}>AM Best rating:</Typography>
            <div className={'flex flex-align-center'}>
              <Typography sx={{color: colors.custom.green.variant2}} className={'fs-12 lh-15 medium'}>{plan.amBestRating}</Typography>
            </div>
          </div>
        </Tooltip>
        <Tooltip placement={'top'} arrow title={'An S&P credit rating is an independent opinion regarding the creditworthiness of a company, for example a healthcare insurance provider. This analysis is based on a comprehensive review of the company\'s financial strength.'}>
          <div className={'mt-8 flex flex-align-centered flex-space-between'}>
            <Typography className={'fs-12 lh-15'}>S&P rating:</Typography>
            <div className={'flex flex-align-center'}>
              <Typography sx={{color: colors.custom.green.variant2}} className={'fs-12 lh-15 medium'}>{plan.spRating}</Typography>
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
    <Tooltip placement={'top'} arrow title={'A discount applied to the monthly plan premium, typically if you reside with a spouse or domestic partner who is also enrolled in a Medicare Supplement plan from the same provider. Eligibility requirements vary by carrier.'}>
      <div className={'mt-16 p-5 mb-10 flex flex-align-center flex-justify-center bg-light-green'}>
        <Typography className={'fs-12 bold lh-15 mr-10'}>Household discount:</Typography>
        <Typography sx={{color: colors.custom.green.variant2}} className={'fs-12 medium'}>
          {plan.householdDiscount ? <>{plan.householdDiscount} <span className="mh-5">|</span> ${((1 - getNumbersFromString(plan.householdDiscount)[0] / 100) * getNumbersFromString(plan.monthlyPremium as string)[0]).toFixed(2)} /mo</> : 'n.a.'}
        </Typography>
        <img className={'w-16 h-16 ml-10'} src={(imgPrefix || '') + '/assets/img/info.svg'}></img>
      </div>
    </Tooltip>
    <Button className={classes.enrollButton}
                  onClick={onEnrollClick}
                  variant={"contained"}>
      Select plan
    </Button>
  </div>

  return <Element name={plan.key}>
    {phone ? Mobile : Desktop}
    {!hideDivider && <Divider sx={{mt: '12px', mx: '20px', borderColor: '#E8ECED'}}/>}
    {hideDivider && <Box height={'13px'}/>}
  </Element>
}

interface NeedPlanProps {
  plan: MedigapPlanOutputWithDetails
}

export const PlanSubtitle = ({plan}: NeedPlanProps) => <Typography className={'fs-12 lh-15 mb-10 medium'}>{[plan.ratingClass, plan.subTitle].filter(v => !!v).join(' | ')}</Typography>

const PricesButton = styled(Button)(
{
  background: '#0A2E86',
  fontSize: 11,
  lineHeight: '14px',
  pointerEvents: 'none',
  padding: '8px',
  '& .MuiButton-endIcon': {
    position: 'absolute',
    right: 5
  },
  maxHeight: 30,
})
